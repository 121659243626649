import { Link } from 'react-router-dom';
import './OurTeam.css';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const davidProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fdavidprofile.png?alt=media&token=90eb7d1e-5299-452a-8940-cdcc867f6325`;
const amirLProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Famirprofile.jpeg?alt=media&token=826b3f64-6409-4c4e-a5b1-25b481ab03be`;
const jacobZProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fjacobprofile.jpg?alt=media&token=c0fcbc7f-8a4c-4c3a-a381-1888b25c2d36`;
const hannahProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fhannahprofile.png?alt=media&token=adcd32e0-6fc1-4361-b2cb-fb751232eb45`;
const jerryProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fjerryhprofile.png?alt=media&token=b992e99b-9e24-4079-8659-9f9a0f547f7c`;
const georgeProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fgeorgeprofile.jpg?alt=media&token=95d84e7b-f352-479a-90af-074877cf2faf`;
const brianProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FbrianProflie.jpg?alt=media&token=2a66628e-9127-4ee2-b881-473b805d6ec2`;
const amirAProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Famiraprofile.png?alt=media&token=b8b270cc-b8bb-4435-877a-311f459e5d2c`;
const peymanProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FpeymanProfile.png?alt=media&token=1fdc2d7d-984e-4a94-af3a-e486a6215a3c`;
const samZProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FsamzProfile.png?alt=media&token=3debb392-ec05-49c4-8afa-0f4d884e53c2`;

const OurTeam = () => (
  <div className="ourteam-container">
    <section id="ourTeam">
      <h2 className="team-section-header">Meet Our Team</h2>
      <div className="team-grid">
        <div className="team-member">
          <img
            src={davidProfileUrl}
            alt="David Freedland Profile"
            className="profile-pic"
          />
          <h3 className="team-name">David Freedland</h3>
          <p className="team-title">Founder and CEO</p>
          <p className="team-about">
            David began his career doing research and product design in the
            decentralized payments space. He then led business development for
            the largest non-OEM hardware and solutions distributor in the DeWi
            industry, where he became a leading expert in deploying
            decentralized wireless infrastructure. David continues to consult
            seed-stage projects in the Web3 and De-Fi space, while primarily
            pursuing his dream of decentralizing telecom.
          </p>
          <p className="location">San Francisco, CA</p>
        </div>
        <div className="team-member">
          <img
            src={amirLProfileUrl}
            alt="Amir Luria Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Amir Luria</h3>
          <p className="team-title">Co-Founder</p>
          <p className="team-about">
            Amir began his banking career at Goldman Sachs, where he worked on
            the Prime Brokerage desk. He now works in the Venture-backed
            securities space, enabling liquidity in Pre-IPO markets while also
            dedicating his off-hours to the deployment of L1 Web3 platforms.
          </p>
          <p className="location">San Diego, CA</p>
        </div>
        <div className="team-member">
          <img
            src={amirAProfileUrl}
            alt="Amir Ali Banari Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Amir Ali Barani</h3>
          <p className="team-title">Deployments and Network Coordinator</p>
          <p className="team-about">
            As the founder of CBRS store, Amir is no stranger to decentralized
            wireless. He has managed commercial installations of various
            networking equipment on towers, billboards, and buildings. His
            hardware expertise and deep industry knowledge are the secret sauce
            behind our rooftop deployments.
          </p>
          <p className="location">Los Angeles, CA</p>
        </div>
        <div className="team-member">
          <img
            src={hannahProfileUrl}
            alt="Hannah Olsen Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Hannah Olsen</h3>
          <p className="team-title">Business Development Executive, Lead</p>
          <p className="team-about">
            Hannah has built a brand and career in the LA music industry,
            working with bars, clubs, concert venues and of course famous
            artists and influencers. This deep network is a force to be reckoned
            with. On top if this, Hannah was the marketing guru of the viral
            platform NoCap Shows.
          </p>
          <p className="location">Los Angeles, CA</p>
        </div>
        <div className="team-member">
          <img
            src={peymanProfileUrl}
            alt="Peyman Mehrban Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Peyman Mehrban</h3>
          <p className="team-title">Solutions Engineer</p>
          <p className="team-about">
            Peyman has been an active deployer across multiple DeWi networks. As
            a partner at CBRS Store, he is experienced with complex
            installations of various kinds of networking equipment.
          </p>
          <p className="location">Los Angeles, CA</p>
        </div>
        <div className="team-member">
          <img
            src={jacobZProfileUrl}
            alt="Jacob Zarling Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Jacob Zarling</h3>
          <p className="team-title">Business Development Executive</p>
          <p className="team-about">
            Coming from commercial aviation, Jacob developed the operational
            roadmap to deploy the first city-wide medical drone network in the
            UAE. His experience identifying opportunities with healthcare
            systems is aligned with building out robust networks in key markets.
          </p>
          <p className="location">San Francisco, CA</p>
        </div>
        <div className="team-member">
          <img
            src={jerryProfileUrl}
            alt="Jerry Healy Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Jerry Healy</h3>
          <p className="team-title">Business Development Executive</p>
          <p className="team-about">
            Expertise in the snow industry, with deep connections throughout
            some of the highest traffic sites across the rockies. This includes
            numerous lounges, hotels, ski shops, resorts, gyms, skateparks, and
            more. Jerry is excited to leverage this network as he moves into
            tech.
          </p>
          <p className="location">Salt Lake City, UT</p>
        </div>
        <div className="team-member">
          <img
            src={georgeProfileUrl}
            alt="George Freedland Profile"
            className="profile-pic"
          />
          <h3 className="team-name">George Freedland</h3>
          <p className="team-title">Software Engineer, IOS & Web</p>
          <p className="team-about">
            George is a seasoned full-stack software developer renowned for his
            adeptness in crafting innovative solutions for a wide array of web
            and mobile applications. With an extensive background spanning
            across both public and private sectors, he has demonstrated a
            remarkable ability to tackle complex challenges and deliver
            impactful results.
          </p>
          <p className="location">San Francisco, CA</p>
        </div>
        <div className="team-member">
          <img
            src={brianProfileUrl}
            alt="Brian Dorsey Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Brian Dorsey</h3>
          <p className="team-title">Graphic Design and Marketing</p>
          <p className="team-about">
            Brian currently works in the advertising industry for clients such
            as Amazon, Bosch, and LinkedIn. His work includes advertising
            campaigns, UI/UX, and art direction. Brian also works as a freelance
            designer specializing in nonprofits and early-stage startups.
          </p>
          <p className="location">Los Angeles, CA</p>
        </div>
        <div className="team-member">
          <img
            src={samZProfileUrl}
            alt="Sam Zarling Profile"
            className="profile-pic"
          />
          <h3 className="team-name">Sam Zarling</h3>
          <p className="team-title">Deployment Operations Specialist</p>
          <p className="team-about">
            Sam is our first hire specializing in technical hardware solutions
            and fleet management. With his expertise, he ensures smooth
            deployment operations.
          </p>
          <p className="location">San Francisco, CA</p>
        </div>
      </div>
    </section>
    <br />
    <br />
    <Link to="/" className="home-link">
      Go back to home
    </Link>
  </div>
);

export default OurTeam;
