import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const localizer = momentLocalizer(moment);

function OpsManageSchedule({ user, setUser }) {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [updating, setUpdating] = useState(false);

  const fetchAvailabilities = async () => {
    try {
      const response = await adminPost(URLS.GET_AVAILABILITIES);
      if (response.ok) {
        const data = await response.json();
        const formattedEvents = data.availabilities.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        setEvents(formattedEvents);
      } else {
        console.error('Failed to fetch availabilities');
      }
    } catch (error) {
      console.error('Error fetching availabilities:', error);
    }
  };

  useEffect(() => {
    fetchAvailabilities();
  }, [user]);

  const handleSelectSlot = ({ start, end }) => {
    const now = new Date();
    if (start < now) {
      return;
    }

    const newEvent = {
      start,
      end,
      title: 'Available',
    };
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleDeleteEvent = () => {
    if (selectedEvent) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) =>
            event.start !== selectedEvent.start ||
            event.end !== selectedEvent.end,
        ),
      );
      setSelectedEvent(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      handleDeleteEvent();
    }
  };

  const handleSubmit = async () => {
    const splitEvents = events.flatMap((event) => {
      const durationInMinutes = moment
        .duration(moment(event.end).diff(event.start))
        .asMinutes();
      if (durationInMinutes <= 30) {
        return [event];
      } else {
        const numberOfSlots = Math.ceil(durationInMinutes / 30);
        const slotDuration = durationInMinutes / numberOfSlots;
        const newEvents = [];
        let currentStart = moment(event.start);
        for (let i = 0; i < numberOfSlots; i++) {
          const currentEnd = moment(currentStart).add(slotDuration, 'minutes');
          newEvents.push({
            start: currentStart.toDate(),
            end: currentEnd.toDate(),
            title: 'Available',
          });
          currentStart = currentEnd;
        }
        return newEvents;
      }
    });
    try {
      setUpdating(true);
      const response = await adminPost(URLS.UPDATE_AVAILABILITY, {
        availability: splitEvents,
      });
      if (response.ok) {
        window.location.reload();
      } else {
        console.error('Failed to update availability');
      }
    } catch (error) {
      console.error('Error updating availability:', error);
    } finally {
      setUpdating(false);
    }
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.title === 'Busy' ? 'orange' : '#3174ad';
    return {
      style: {
        backgroundColor,
      },
    };
  };

  return (
    <div
      className="custom-form-1-admin-dash-container"
      onKeyDown={handleKeyDown}
    >
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Manage Availability</h2>
      <div style={{ height: '500px' }}>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          defaultView="week"
          views={['week', 'day']}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
        />
      </div>
      {updating ? (
        <SmallSpinner />
      ) : (
        <button className="custom-form-1-back-link" onClick={handleSubmit}>
          Submit
        </button>
      )}
    </div>
  );
}

export default OpsManageSchedule;
