import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/Rhythm Wireless Logo Horizontal - White.svg';
import HamburgerButton from './HamburgerButton';
import SidePanel from './SidePanel';
import Modal from './Modal';
import './Header.css';

const Header = ({
  signupModalOpen,
  toggleSignupModal,
  loginModalOpen,
  toggleLoginModal,
  loginClient,
  client,
  admin,
  logout,
  adminLoading,
  clientLoading,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
    clickHeaderItem();
  };

  const clickLogoutItem = () => {
    logout();
    clickHeaderItem();
  };

  const clickHeaderItem = () => {
    if (loginModalOpen) {
      toggleLoginModal();
    }
    if (signupModalOpen) {
      toggleSignupModal();
    }
  };

  const clickSignUpHeaderButton = () => {
    if (loginModalOpen) {
      toggleLoginModal();
    }
    toggleSignupModal();
  };

  const clickLoginHeaderButton = () => {
    if (signupModalOpen) {
      toggleSignupModal();
    }
    toggleLoginModal();
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const { hash } = location;
    if (hash) {
      const sectionId = hash.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const offset = document.getElementById('main-header').offsetHeight;
        const sectionPosition = section.offsetTop - offset;
        window.scrollTo({
          top: sectionPosition,
          behavior: 'smooth',
        });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 828);
      setIsPanelOpen(window.innerWidth > 828 && setIsPanelOpen(false));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <header id="main-header">
        <div className="logo-container" onClick={clickHeaderItem}>
          <Link to="/">
            <img src={logo} alt="Rhythm Logo" className="rhythm-logo" />
          </Link>
        </div>

        {isMobile ? (
          <>
            <HamburgerButton onClick={togglePanel} />
            <SidePanel
              isOpen={isPanelOpen}
              onClose={togglePanel}
              client={client}
              admin={admin}
              logout={logout}
              clientLoading={clientLoading}
              adminLoading={adminLoading}
              toggleLogin={toggleLoginModal}
            />
          </>
        ) : (
          <nav className="nav-container">
            <ul>
              {!clientLoading && !adminLoading && (
                <>
                  {client && (
                    <li onClick={clickHeaderItem}>
                      <Link to="/account">Account</Link>
                    </li>
                  )}
                  <li onClick={clickHeaderItem}>
                    <Link to="/#howItWorks">How it works</Link>
                  </li>
                  <li onClick={clickHeaderItem}>
                    <Link to="/#faq">FAQ</Link>
                  </li>
                  <li onClick={clickHeaderItem}>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li onClick={clickHeaderItem}>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li onClick={clickHeaderItem}>
                    <Link to="/referrals">Referrals</Link>
                  </li>
                  {!client && !admin && (
                    <>
                      <li onClick={clickHeaderItem}>
                        <button
                          className="dropbtn-2"
                          onClick={clickLoginHeaderButton}
                        >
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={clickSignUpHeaderButton}
                          className="dropbtn"
                        >
                          Sign up
                          <i className="fa fa-caret-down"></i>
                        </button>
                      </li>
                    </>
                  )}
                  {(client || admin) && (
                    <li onClick={clickLogoutItem}>
                      <Link to="/">Logout</Link>
                    </li>
                  )}
                </>
              )}
            </ul>
          </nav>
        )}
      </header>
      {signupModalOpen && (
        <Modal
          onClose={toggleSignupModal}
          type="signup"
          loginClient={loginClient}
        />
      )}
      {loginModalOpen && (
        <Modal
          onClose={toggleLoginModal}
          type="login"
          loginClient={loginClient}
        />
      )}
    </>
  );
};

export default Header;
