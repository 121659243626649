import React, { useEffect, useState } from 'react';
import './Referrals.css';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const rhythmReferralsDesktop = `${REACT_APP_FIREBASE_STORAGE_URL}graphics%2Frhythm_referrals_desktop.png?alt=media&token=04d0d5f7-605d-4387-b266-9291504c0689`;
const rhythmReferralsMobile = `${REACT_APP_FIREBASE_STORAGE_URL}graphics%2Frhythm_referrals_mobile.png?alt=media&token=da058539-1c2e-4f53-aaff-ebc2f7cb6556`;

const Referrals = () => {
  const [imageUrl, setImageUrl] = useState(
    window.innerWidth >= 768 ? rhythmReferralsDesktop : rhythmReferralsMobile,
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const isWindowMobile = window.innerWidth < 768;
      if (isWindowMobile !== isMobile) {
        setIsMobile(isWindowMobile);
      }
      setImageUrl(
        isWindowMobile ? rhythmReferralsMobile : rhythmReferralsDesktop,
      );
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <>
      {isMobile && (
        <div className="referrals-container">
          <h2 className="referrals-section-header">Referral Program</h2>
        </div>
      )}
      <img src={imageUrl} alt="Referrals" className="referrals-section-image" />
    </>
  );
};

export default Referrals;
