import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants';
import { clientPost } from '../../../utils';
import Spinner from '../../../components/Spinner';

const MenuPage = ({ client, setClient }) => {
  const [metadata, setMetadata] = useState({
    earnings: {
      monthlyEarnings: '0.00',
      currentMonth: '',
      siteAmount: 0,
      deviceAmount: 0,
    },
    appointments: {
      upcomingAppointments: 0,
      mostRecentAppointment: null,
    },
    payout: {
      methods: false,
    },
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await clientPost(URLS.GET_CLIENT_MENU);
        if (response.ok) {
          const data = await response.json();
          setMetadata(data);
        } else {
          console.error('Failed to fetch metadata');
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, []);

  const getCurrentMonth = () => {
    const currentDate = new Date();

    // Get the current month (returns a zero-based index, so January is 0)
    const currentMonth = currentDate.getMonth();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const currentMonthName = monthNames[currentMonth];

    return currentMonthName;
  };

  const goToPayout = async () => {
    try {
      const response = await clientPost(URLS.GO_TO_PAYOUT);
      if (response.ok) {
        setClient({ status: 'PAYOUT' });
        navigate('/account');
      } else {
        console.error('Failed to skip payout.');
      }
    } catch (error) {
      console.error('Error skipping payout:', error);
    }
  };

  return (
    <div className="custom-grid-container">
      <div className="custom-panel">
        <div className="custom-panel-link">
          <h2 className="custom-panel-title">My Sites and Earnings</h2>
          <p className="custom-panel-text">
            Earning details for{' '}
            {metadata.earnings.currentMonth ?? getCurrentMonth()} coming soon!
          </p>
        </div>
      </div>
      <div className="custom-panel">
        <div className="custom-panel-link">
          <h2 className="custom-panel-title">My Appointments</h2>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {metadata.appointments.upcomingAppointments ? (
                <>
                  <p className="custom-panel-text">
                    You have {metadata.appointments.upcomingAppointments ?? 0}{' '}
                    upcoming appointment
                    {metadata.appointments.upcomingAppointments !== 1 &&
                      metadata.appointments.upcomingAppointments !== '1' &&
                      's'}
                    .
                  </p>
                  {metadata.appointments.mostRecentAppointment && (
                    <p className="custom-panel-text">
                      Your next appointment date is:{' '}
                      {metadata.appointments.mostRecentAppointment}
                    </p>
                  )}
                </>
              ) : (
                <p className="custom-panel-text">No upcoming appointments.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className="custom-panel">
        <div className="custom-panel-link">
          <h2 className="custom-panel-title">Payout Options</h2>
          {!metadata.payout.methods ? (
            <Spinner />
          ) : (
            <>
              <div className="custom-panel-text">
                {!metadata.payout.methods.length ? (
                  <button
                    className="menu-page-button"
                    type="button"
                    onClick={goToPayout}
                  >
                    Add payout method
                  </button>
                ) : (
                  metadata.payout.methods.map((item, key) => {
                    return (
                      <div key={key}>
                        {item === 'STRIPE' && (
                          <div className="payout-method">ACH via Stripe</div>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        For any questions please contact us at{' '}
        <a href={`mailto:info@rhythmwireless.io`} className="contact-us-email">
          info@rhythmwireless.io
        </a>
        .
      </div>
    </div>
  );
};

export default MenuPage;
