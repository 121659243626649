import { Link } from 'react-router-dom';
import { useState } from 'react';
import { adminPost } from '../../utils';
import { HOUSEHOLD_TYPE, URLS } from '../../constants';
import './Admin.css';
import Spinner from '../../components/Spinner';

const PotentialHouseholdClientsAdd = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    householdEmail: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    ownership: HOUSEHOLD_TYPE.RENTER,
    referredBy: '',
    additionalNotes: '',
    readyForReview: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      setSubmitting(true);
      const response = await adminPost(URLS.ADD_POTENTIAL_HOUSEHOLD, formData);
      if (!response.ok) {
        const body = await response.json();
        console.error(`Error adding potential household: ${body.error}`);
        setErrorMessage(`Error adding potential household: ${body.error}`);
        setSubmitting(false);
        return;
      }
      setSuccessMessage('Potential client succesfully added.');
    } catch (error) {
      setErrorMessage('Error adding potential household');
      console.error('Error adding potential household:', error);
    }
    setSubmitting(false);

    // Reset form fields
    setFormData({
      fullName: '',
      householdEmail: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      ownership: HOUSEHOLD_TYPE.RENTER,
      referredBy: '',
      additionalNotes: '',
      readyForReview: false,
    });
  };

  return (
    <div className="custom-form-1-admin-dash-container">
      <Link
        to="/admin/potential-household-clients"
        className="custom-form-1-back-link"
      >
        Back To Potential Household Clients
      </Link>
      <h2>Add Potential Household Client</h2>
      <form className="custom-form-1-comment-form" onSubmit={handleSubmit}>
        <div className="custom-form-1-form-group">
          <label htmlFor="fullName" className="custom-form-1-label">
            Full Name:
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter full name"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="householdEmail" className="custom-form-1-label">
            Email:
          </label>
          <input
            type="email"
            id="householdEmail"
            name="householdEmail"
            value={formData.householdEmail}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter email address"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="phoneNumber" className="custom-form-1-label">
            Phone Number:
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter phone number"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="address" className="custom-form-1-label">
            Address:
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter address"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="city" className="custom-form-1-label">
            City:
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter city"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="state" className="custom-form-1-label">
            State:
          </label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter state"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="zipCode" className="custom-form-1-label">
            Zip Code:
          </label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter zip code"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="ownership" className="custom-form-1-label">
            Ownership:
          </label>
          <select
            id="ownership"
            name="ownership"
            value={formData.ownership}
            onChange={handleChange}
            className="custom-form-1-select"
          >
            <option value={HOUSEHOLD_TYPE.RENTER}>Renter</option>
            <option value={HOUSEHOLD_TYPE.OWNER}>Owner</option>
          </select>
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="referredBy" className="custom-form-1-label">
            Referred By (Optional):
          </label>
          <input
            type="text"
            id="referredBy"
            name="referredBy"
            value={formData.referredBy}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="(Optional) Enter who referred the client"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="additionalNotes" className="custom-form-1-label">
            Sales Notes (Optional):
          </label>
          <textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={handleChange}
            className="custom-form-1-textarea"
            placeholder="(Optional) Enter any sales notes for the Ops Team"
          ></textarea>
        </div>
        <div className="custom-form-1-form-group">
          <label className="custom-form-1-label">
            Ready for Review by Ops Team:
          </label>
          <input
            type="checkbox"
            id="readyForReview"
            name="readyForReview"
            checked={formData.readyForReview}
            onChange={handleChange}
            className="custom-form-1-checkbox"
          />
          <label
            htmlFor="readyForReview"
            className="custom-form-1-checkbox-label"
          >
            Ready for Review
          </label>
        </div>
        {submitting ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="custom-form-1-button"
            disabled={
              !formData.fullName ||
              !formData.householdEmail ||
              !formData.phoneNumber ||
              !formData.address ||
              !formData.city ||
              !formData.state ||
              !formData.zipCode
            }
          >
            Submit
          </button>
        )}
        {errorMessage && (
          <p className="custom-form-1-error-message">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="custom-form-1-success-message">{successMessage}</p>
        )}
      </form>
    </div>
  );
};

export default PotentialHouseholdClientsAdd;
