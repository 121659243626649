import React from 'react';
import './SmallSpinner.css';

const SmallSpinner = () => {
  return (
    <div className="lds-small-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SmallSpinner;
