import NotFound from '../../components/NotFound';
import ContractPage from './onboarding/ContractPage';
import SchedulingPage from './onboarding/SchedulingPage';
import MenuPage from './onboarding/MenuPage';
import PayoutPage from './onboarding/PayoutPage';
import { CLIENT_STATUS } from '../../constants';

const Account = ({ client, setClient }) => {
  let pageContent;

  switch (client.status) {
    case CLIENT_STATUS.CONTRACT:
      pageContent = <ContractPage setClient={setClient} />;
      break;
    case CLIENT_STATUS.APPOINTMENT:
      pageContent = <SchedulingPage client={client} setClient={setClient} />;
      break;
    case CLIENT_STATUS.PAYOUT:
      pageContent = <PayoutPage client={client} setClient={setClient} />;
      break;
    case CLIENT_STATUS.HOME:
      pageContent = <MenuPage client={client} setClient={setClient} />;
      break;
    default:
      pageContent = <NotFound />;
  }

  return pageContent;
};

export default Account;
