import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './MagicInfraSignup.css';
import Cookies from 'js-cookie';
import NotFound from '../../components/NotFound';
import Spinner from '../../components/Spinner';
import { post } from '../../utils';
import { URLS } from '../../constants';

const MagicInfraSignup = ({ login }) => {
  const { magic_code } = useParams();
  const navigate = useNavigate();
  const [validMagicCode, setValidMagicCode] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [idToUpdate, setIdToUpdate] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [passwordError, setPasswordError] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    passLength: false,
    passwordMatch: false,
  });
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const validateMagicCode = async () => {
      try {
        const response = await post(URLS.VALIDATE_INFRA_MAGIC_CODE, {
          magic_code,
        });
        if (!response.ok) {
          setError('Magic code validation failed.');
          setLoading(false);
        } else {
          const data = await response.json();
          setRole(data.role);
          setEmail(data.email);
          setIdToUpdate(data.id);
          setValidMagicCode(true);
          setLoading(false);
        }
      } catch (error) {
        setError('Invalid magic code.');
        setLoading(false);
      }
    };

    validateMagicCode();
  }, [magic_code]);

  useEffect(() => {
    const errors = {
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*[0-9])/.test(password),
      special: !/(?=.*[!@#$%^&*()_+{}[\]:;<>,./?|-])/.test(password),
      passLength: password.length >= 11,
      passwordMatch:
        password === repeatPassword && (password || repeatPassword),
    };
    setPasswordError(errors);
  }, [password, repeatPassword]);

  const handleShowHideButton = (event) => {
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  const handleSignup = async () => {
    setSubmitting(true);
    try {
      const response = await post(URLS.UPDATE_INFRA_USER, {
        email,
        password,
        role,
        idToUpdate,
      });
      if (!response.ok) {
        setError('User signup failed.');
        setSubmitting(false);
      } else {
        const data = await response.json();
        Cookies.set('token', data.token);
        Cookies.set('email', email);
        const user = {
          email,
          role,
        };
        login(user);
        navigate('/admin/dashboard');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div>
      {validMagicCode ? (
        <div className="magic-signup-container">
          <p>
            Hello {email}
            <br /> Please add a password to finish setting up your account.
          </p>
          <div style={{ position: 'relative' }}>
            <input
              type={hiddenPassword ? 'password' : 'text'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength={40}
            />
            <button
              tabIndex="-1"
              style={{
                position: 'absolute',
                color: 'black',
                fontSize: '6pt',
                display: 'flex',
                padding: '0.4rem',
                paddingBottom: '1rem',
                margin: 'auto',
                top: '50%',
                left: '90%',
                height: '2px',
                maxWidth: '15%',
                transform: 'translateY(-50%) translateX(-50%)',
                backgroundColor: 'lightgrey',
                border: 'solid black',
                borderColor: 'gray',
                cursor: 'pointer',
                justifyContent: 'center',
                borderRadius: '10%',
              }}
              onClick={handleShowHideButton}
            >
              {hiddenPassword ? 'Show' : 'Hide'}
            </button>
          </div>
          <input
            type="password"
            placeholder="Repeat Password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            maxLength={40}
            onKeyDown={handleKeyDown}
          />
          {submitting ? (
            <Spinner />
          ) : (
            <button
              disabled={
                passwordError.lowercase ||
                passwordError.uppercase ||
                passwordError.number ||
                passwordError.special ||
                !passwordError.passLength ||
                !passwordError.passwordMatch
              }
              onClick={handleSignup}
            >
              Sign Up
            </button>
          )}
          <div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: passwordError.lowercase ? 'block' : 'none',
              }}
            >
              Password must contain at least one lowercase letter.
            </div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: passwordError.uppercase ? 'block' : 'none',
              }}
            >
              Password must contain at least one uppercase letter.
            </div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: passwordError.number ? 'block' : 'none',
              }}
            >
              Password must contain at least one number.
            </div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: passwordError.special ? 'block' : 'none',
              }}
            >
              Password must contain at least one special character.
            </div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: !passwordError.passLength ? 'block' : 'none',
              }}
            >
              Password must be at least 11 characters long.
            </div>
            <div
              style={{
                marginBottom: '0.5rem',
                fontSize: '10pt',
                display: !passwordError.passwordMatch ? 'block' : 'none',
              }}
            >
              Passwords must match.
            </div>
          </div>
          {error && <p>{error}</p>}
        </div>
      ) : loading ? (
        <Spinner />
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default MagicInfraSignup;
