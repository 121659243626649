import React from 'react';
import './HamburgerButton.css';

const HamburgerButton = ({ onClick }) => {
  return (
    <button className="hamburger-button" onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default HamburgerButton;
