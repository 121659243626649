import './AdminDashboard.css';
import { Link } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import { ROLE } from '../../constants';

const AdminDashboard = ({ user }) => {
  if (!user || !user.role) {
    return <NotFound />;
  }

  const { role } = user;

  const renderAdminFunctions = () => {
    return (
      <>
        <hr />
        <h3>Admin Functions</h3>
        <ul className="admin-dash-ul">
          <Link to="/admin/users" className="admin-dash-li">
            Internal Users
          </Link>
          <Link to="/admin/client-documents" className="admin-dash-li">
            Client Documents
          </Link>
        </ul>
      </>
    );
  };

  const renderSalesFunctions = () => {
    return (
      <>
        <hr />
        <h3>Sales Functions</h3>
        <ul className="admin-dash-ul">
          <Link
            to="/admin/potential-household-clients"
            className="admin-dash-li"
          >
            Potential Household Clients
          </Link>
          <Link
            to="/admin/potential-business-clients"
            className="admin-dash-li"
          >
            Potential Business Clients
          </Link>
          <Link to="/admin/active-clients" className="admin-dash-li">
            Active Clients
          </Link>
        </ul>
      </>
    );
  };

  const renderOpsFunctions = () => {
    return (
      <>
        <hr />
        <h3>Ops Functions</h3>
        <ul className="admin-dash-ul">
          <Link
            to="/admin/manage-ops-potential-sites"
            className="admin-dash-li"
          >
            Approve/Reject New Potential Sites
          </Link>
          <Link
            to="/admin/assign-installer-active-sites"
            className="admin-dash-li"
          >
            Approve/Reject Sites Added to Existing Accounts
          </Link>
          <Link to="/admin/manage-ops-schedule" className="admin-dash-li">
            Manage My Schedule
          </Link>
          <Link to="/admin/manage-ops-sites" className="admin-dash-li">
            My Accounts (Where I am the Ops Rep)
          </Link>
        </ul>
      </>
    );
  };

  const renderInstallerFunctions = () => {
    return (
      <>
        <hr />
        <h3>Installer Functions</h3>
        <ul className="admin-dash-ul">
          <Link to="/admin/manage-ops-schedule" className="admin-dash-li">
            Manage My Schedule
          </Link>
          <Link to="/admin/manage-installer-sites" className="admin-dash-li">
            My Accounts (Where I am the Installer)
          </Link>
        </ul>
      </>
    );
  };

  const renderBasedOnRole = () => {
    switch (role) {
      case ROLE.ADMIN:
        return (
          <>
            {renderAdminFunctions()}
            {renderSalesFunctions()}
            {renderOpsFunctions()}
            {renderInstallerFunctions()}
          </>
        );
      case ROLE.SALES:
        return renderSalesFunctions();
      case ROLE.OPS:
        return renderOpsFunctions();
      case ROLE.INSTALLER:
        return renderInstallerFunctions();
      default:
        return <NotFound />;
    }
  };

  return (
    <div className="admin-dash-container">
      <h3>Hi {user.email}!</h3>
      <h2>Admin Dashboard</h2>
      {renderBasedOnRole()}
    </div>
  );
};

export default AdminDashboard;
