import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HOUSEHOLD_TYPE, URLS } from '../../constants';
import { post } from '../../utils';
import './Forms.css';

const Household = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    ownership: '',
    referredBy: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.SUBMIT_HOUSEHOLD_FORM, formData);
      if (response.ok) {
        setSubmitted(true);
        setErrorMessage('');
      } else {
        const errorMessage = await response.text();
        setErrorMessage(errorMessage || 'Error submitting form.');
      }
    } catch (error) {
      setErrorMessage(
        'Our servers are currently down, please try again later.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="household-container">
      <section id="household">
        <h2 className="household-section-header">Households</h2>
        <h2 className="household-section-subtitle">
          Property Managers, Homeowners and Renters
        </h2>
        <div className="text-block">
          <form onSubmit={handleSubmit} className="household-form">
            <div className="form-group">
              <input
                placeholder="Full Name"
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="form-input"
                pattern="[A-Za-z ]*"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Street Address"
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
                className="form-input"
                maxLength="70"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="City"
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="State"
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="form-input"
                maxLength="40"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Zip Code"
                type="text"
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                className="form-input"
                maxLength="20"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Phone Number"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="form-input"
                maxLength="22"
              />
            </div>
            <div className="form-group">
              <label className="checkbox-text">
                <input
                  type="checkbox"
                  name="ownership"
                  value={HOUSEHOLD_TYPE.RENTER}
                  checked={formData.ownership === HOUSEHOLD_TYPE.RENTER}
                  onChange={handleChange}
                />{' '}
                Renter
              </label>
            </div>
            <div className="form-group">
              <label className="checkbox-text">
                <input
                  type="checkbox"
                  name="ownership"
                  value={HOUSEHOLD_TYPE.OWNER}
                  checked={formData.ownership === HOUSEHOLD_TYPE.OWNER}
                  onChange={handleChange}
                />{' '}
                Owner
              </label>
            </div>
            <div className="form-group">
              <input
                placeholder="Referred By (Optional)"
                type="text"
                id="referredBy"
                name="referredBy"
                value={formData.referredBy}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <Link
              to="/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="form-agreement-link"
            >
              Terms and Conditions
            </Link>
            {errorMessage && <div className="error-box">{errorMessage}</div>}
            {submitted && (
              <div className="success-box">
                Thanks for signing up for our waitlist! We will get back to you
                soon to confirm if your location is a match.
              </div>
            )}
            <button
              type="submit"
              className="form-submit"
              disabled={
                submitting ||
                submitted ||
                !formData.fullName ||
                !formData.email ||
                !formData.streetAddress ||
                !formData.city ||
                !formData.state ||
                !formData.zipCode ||
                !formData.phoneNumber ||
                !formData.ownership
              }
            >
              {submitting ? (
                <div className="spinner" />
              ) : submitted ? (
                'Submitted!'
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
        <br />
        <br />
        <br />
      </section>
    </div>
  );
};

export default Household;
