import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';

import './AdminUsers.css';

import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const token = Cookies.get('token');
  const username = Cookies.get('username');
  const email = Cookies.get('email');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await adminPost(URLS.GET_ADMIN_USERS);
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [token, username, email]);

  return (
    <div className="admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Internal Users</h2>
      <Link className="admin-invite-link" to="/admin/invite-user">
        Invite User
      </Link>
      <br />
      <br />
      <hr />
      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p style={{ textAlign: 'center' }}>No users.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email}>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{getFormattedDate(user.created)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
