import Cookies from 'js-cookie';
const { REACT_APP_BACKEND_URL } = process.env;

export const removeAllCookies = () => {
  const cookies = Object.keys(Cookies.get());
  cookies.forEach((cookie) => Cookies.remove(cookie));
};

export const post = async (url, body) => {
  return await fetch(`${REACT_APP_BACKEND_URL}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const clientPost = async (url, body) => {
  if (!body) {
    body = {};
  }

  body.clientToken = Cookies.get('clientToken');
  body.clientEmail = Cookies.get('clientEmail');

  return await fetch(`${REACT_APP_BACKEND_URL}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const adminPost = async (url, body) => {
  if (!body) {
    body = {};
  }

  body.token = Cookies.get('token');
  body.email = Cookies.get('email');

  return await fetch(`${REACT_APP_BACKEND_URL}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const adminUpload = async (url, { file, userid, siteId }) => {
  if (!file) {
    console.error('No file provided');
    return { success: false, message: 'No file provided' };
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('userid', userid);
  formData.append('siteId', siteId);
  formData.append('email', Cookies.get('email'));
  formData.append('token', Cookies.get('token'));

  try {
    const response = await fetch(`${REACT_APP_BACKEND_URL}/${url}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      console.error('Failed to upload file');
      return { success: false, message: 'Failed to upload file' };
    }

    return { success: true, message: 'File uploaded successfully' };
  } catch (error) {
    console.error('Error uploading file:', error);
    return { success: false, message: 'Error uploading file' };
  }
};
