import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './Job.css';

import NotFound from '../../components/NotFound';

const Job = () => {
  const { job_name } = useParams();

  const renderJobContent = () => {
    if (job_name === 'business-development-regional-lead') {
      return (
        <div className="job-container">
          <h2 className="job-section-header">
            Business Development Regional Lead
          </h2>
          <p>
            This is a fast paced role at a rapidly growing startup at the
            cutting edge of Telecom.
          </p>
          <b>Who you are:</b>
          <p>
            A combination of impeccable interpersonal skills, entrepreneurial
            drive, polished professionalism, as well as drive to constantly
            exceed both personal goals as well as management expectations will
            all be key factors to succeed in this role. We are particularly
            interested in those who thrive on a commission driven compensation
            structure, as they are confident in their abilities to create value
            for the business.
          </p>
          <b>Company Culture:</b>
          <p>
            We work with a spirit of collaboration. Every rep has a distinct
            territory and a designated mentor to help them ramp up. While our
            reps do not compete for sales, we do offer team bonuses when we all
            do well, as well as individual bonuses for generating value for
            other teams, encouraging our team to work together and enable each
            other's success. We are a fast paced company that loves to work hard
            and also have fun together with travel to various amazing locations
            for team building retreats (last one was in Hawaii).
          </p>
          <b>Compensation:</b>
          <p>
            On-target earnings your first month will range from 6-12k. There is
            room for significant growth as you ramp up, top performing reps are
            expected to earn 160-260k yearly. Those who excel through this
            process will be considered for our enterprise partnerships role and
            other strategic and managerial roles opening later this year.
          </p>
          <b>Job Description:</b>
          <p>
            Our sales reps are responsible for signing new customers for
            deployment of telecommunications hotspots in high-traffic sites
            across their region, primarily storefronts, restaurants, and
            property owners. Since we are not selling a service but rather
            offering to pay hosts monthly and totally passively, the pitch tends
            to go very smoothly. This job is all about working smarter not
            harder, we pay a 400-1200 dollars (USD) commission for every
            successful site signed that meets our criteria. as well as 2% of the
            revenue on that account, on going, for as long as you continue to
            manage this account. This will give our reps incentives to grow
            large fleets and capitalize on the growth they are creating. Reps
            are responsible for sourcing their own business, though we will
            provide leads, targeted advertisements, and other resources to drive
            inbound sales directly to our reps. That said, sales reps who thrive
            in this role are not afraid of cold calls or in-person meetings.
          </p>
          <b>Job Requirements:</b>
          <ul>
            <li>Bachelor's degree or equivalent work experience.</li>
            <li>
              Sales/Marketing experience or similar experience in a
              client-driven role.
            </li>
            <li>Superior communication and interpersonal skills.</li>
            <li>
              Organizational skills to set your own hours and take
              responsibility for your own schedule.
            </li>
            <li>Ability to thrive in a results-driven environment.</li>
            <li>
              Familiarity with CRM and project management tools, excel, power
              point, etc.
            </li>
            <li>
              Interest in tech, strong connections with relevant businesses or a
              keen ability to network.
            </li>
          </ul>
          <b>Bonus:</b>
          <ul>
            <li>Previous experience and success in commission driven roles.</li>
            <li>MBA, Industry experience, or entrepreneurship.</li>
            <li>
              Previous start-up, field sales, or working with brick and mortar
              establishments.
            </li>
          </ul>
          <p>
            Please Send Job Applications to{' '}
            <a href={`mailto:david@rhythmwireless.io`} className="job-email">
              david@rhythmwireless.io
            </a>{' '}
            with a short introduction about yourself, where you are located and
            attach a pdf resume as well as a brief cover letter.
          </p>
          <p>Please note that this is a contract role.</p>
          <br />
          <br />
          <Link to="/careers" className="home-link">
            Go back to jobs
          </Link>
        </div>
      );
    } else {
      return <NotFound />;
    }
  };

  return <div>{renderJobContent()}</div>;
};

export default Job;
