import { useState } from 'react';
import Cookies from 'js-cookie';
import Spinner from '../../components/Spinner';
import './AdminSignIn.css';

import { URLS } from '../../constants';
import { post } from '../../utils';
import { Link } from 'react-router-dom';

const AdminSignIn = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.ADMIN_SIGNIN, { adminEmail, password });

      if (!response.ok) {
        setErrorMessage('Invalid email or password');
        setSubmitting(false);
        return;
      }

      const data = await response.json();

      Cookies.set('token', data.token);
      Cookies.set('email', data.email);
      window.location.href = '/admin/dashboard';
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  };

  const handleShowHideButton = (event) => {
    if (event.key === 'Enter') {
      return;
    }
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  return (
    <div className="admin-signin-container">
      <form onSubmit={handleSignIn} className="admin-invite-form">
        <h2>Admin Sign In</h2>
        {errorMessage && (
          <div className="admin-error-message">{errorMessage}</div>
        )}
        <input
          className="admin-signin-container-input"
          type="email"
          placeholder="Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
          maxLength={40}
          autoComplete="username"
        />
        <div style={{ position: 'relative' }}>
          <input
            className="admin-signin-container-input"
            type={hiddenPassword ? 'password' : 'text'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            maxLength={40}
            autoComplete="current-password"
          />
          <button
            type="button"
            style={{
              position: 'absolute',
              color: 'black',
              fontSize: '6pt',
              display: 'flex',
              padding: '0.4rem',
              paddingBottom: '1rem',
              margin: 'auto',
              top: '39%',
              left: '90%',
              height: '2px',
              maxWidth: '15%',
              transform: 'translateY(-50%) translateX(-50%)',
              backgroundColor: 'lightgrey',
              border: 'solid black',
              borderColor: 'gray',
              cursor: 'pointer',
              justifyContent: 'center',
              borderRadius: '10%',
            }}
            onClick={handleShowHideButton}
            onKeyDown={handleShowHideButton}
            tabIndex="-1"
          >
            {hiddenPassword ? 'Show' : 'Hide'}
          </button>
        </div>
        {!submitting ? (
          <button
            disabled={!adminEmail || !password}
            className="admin-signin-container-button"
          >
            Sign In
          </button>
        ) : (
          <Spinner />
        )}
        <Link to="/admin/forgot-password">Forgot password?</Link>
      </form>
    </div>
  );
};

export default AdminSignIn;
