import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SidePanel.css';

const SidePanel = ({
  isOpen,
  onClose,
  client,
  admin,
  logout,
  clientLoading,
  adminLoading,
  toggleLogin,
}) => {
  useEffect(() => {
    const handleBodyOverflow = (isOpen) => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    };
    handleBodyOverflow(isOpen);
    return () => {
      handleBodyOverflow(isOpen);
    };
  }, [isOpen]);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains('backdrop')) {
      onClose();
    }
  };

  const onLogout = () => {
    onClose();
    logout();
  };

  const onLogin = () => {
    onClose();
    toggleLogin();
  };

  return (
    <>
      {isOpen && <div className="backdrop" onClick={handleBackdropClick}></div>}
      <div className={`side-panel ${isOpen ? 'open' : ''}`}>
        {!clientLoading && !adminLoading && (
          <ul>
            {client && (
              <li>
                <Link to="/account" onClick={onClose}>
                  Account
                </Link>
              </li>
            )}
            {!client && !admin && (
              <>
                <li className="sidebar-clickable">
                  <div onClick={onLogin}>Login</div>
                </li>
                <li>
                  <Link to="/business" onClick={onClose}>
                    Businesses
                  </Link>
                </li>
                <li>
                  <Link to="/household" onClick={onClose}>
                    Households
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to="/#howItWorks" onClick={onClose}>
                How it works
              </Link>
            </li>
            <li>
              <Link to="/#faq" onClick={onClose}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/our-team" onClick={onClose}>
                Our Team
              </Link>
            </li>
            <li>
              <Link to="/careers" onClick={onClose}>
                Careers
              </Link>
            </li>
            <li>
              <Link to="/referrals" onClick={onClose}>
                Referrals
              </Link>
            </li>
            {(client || admin) && (
              <li>
                <Link to="/" onClick={onLogout}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default SidePanel;
