import React, { useState } from 'react';
import './Faq.css';

const Faq = () => {
  const [isOpen, setIsOpen] = useState({});

  const toggleQuestion = (question) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };

  return (
    <div className="faq-container">
      <section id="faq">
        <h2 className="faq-section-header">FAQ</h2>
        <div className="text-block">
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('bandwidth')}
          >
            <b className="question">
              Does this device pull bandwidth from my current wifi?
              <span className={`arrow ${isOpen['bandwidth'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['bandwidth'] ? 'open' : ''}`}>
              The device should not use much bandwidth. It only uses up
              bandwidth when customers are using your network. If you notice any
              decrease to your speed, this is a great problem to have as it
              means this site is earning a lot through cellular traffic. In
              these cases, we will pay to upgrade your internet plan and you can
              expect substantial earnings. Nearly all customers so far have not
              noticed any differences in their network speeds.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('pay')}
          >
            <b className="question">
              How much does this pay?
              <span className={`arrow ${isOpen['pay'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['pay'] ? 'open' : ''}`}>
              We guarantee $50 a month, but you also get 10% of any earnings on
              top of that!
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('security')}
          >
            <b className="question">
              Is it safe/secure?
              <span className={`arrow ${isOpen['security'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['security'] ? 'open' : ''}`}>
              Absolutely. Data security is our priority. Your data is completely
              secure and your call and messages will not be tracked. Adll these
              devices have been tested and approved by all the major telecom
              companies and all the software has been developed by leaders in
              encryption and cybersecurity.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('eligible')}
          >
            <b className="question">
              Am I eligible? I’m interested, can I do this?
              <span className={`arrow ${isOpen['eligible'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['eligible'] ? 'open' : ''}`}>
              We will have some questions for you to confirm if you're eligible:
              <br />
              <br /> 1) What area are you located?
              <br /> 2) Do you have a storefront, home, or apartment?
              <br /> 3) Do you own this property? If renting, are you planning
              on staying for the next year or longer?
              <br /> 4) Can you please do an internet speed test when you are
              connected to your wifi and let us know what your download and
              upload speeds are? You can simply type "internet speed test" into
              search or use this link:{' '}
              <a
                href="https://fiber.google.com/speedtest/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://fiber.google.com/speedtest
              </a>
              <br />
              If you are using this on your phone please switch to airplane mode
              when doing the speed test.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('options')}
          >
            <b className="question">
              What are my options?
              <span className={`arrow ${isOpen['options'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['options'] ? 'open' : ''}`}>
              We have two different devices, one is smaller and intended for
              businesses with a lot of foot traffic. The other is larger and
              would be placed on a roof, balcony, or external wall. The larger
              device does take a bit longer to install, but it also makes you
              more money.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('model')}
          >
            <b className="question">
              Could I have more information on your business model and what
              exactly you are getting out of these hotspots?
              <span className={`arrow ${isOpen['model'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['model'] ? 'open' : ''}`}>
              We set up Wi-Fi 6 hotspots for different cellular carriers that
              want coverage in your area. Most businesses only use a small
              fraction of the Wi-Fi that they're paying for and the rest goes to
              waste. The hotspot allows you to get paid for that unused Wi-Fi by
              providing local cell traffic. Our company is building a network
              that is valuable to carriers at scale. So as we get more valuable
              sites in high-traffic areas, we're able to monetize that coverage.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('networks')}
          >
            <b className="question">
              What networks does your device support? Who will get better cell
              service?
              <span className={`arrow ${isOpen['networks'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['networks'] ? 'open' : ''}`}>
              AT&T, T-Mobile, Helium, Telefonica, and a few smaller carriers.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('payment')}
          >
            <b className="question">
              How do I get paid?
              <span className={`arrow ${isOpen['payment'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['payment'] ? 'open' : ''}`}>
              You can choose to be paid via Venmo, Paypal, Wire, ACH, or Crypto.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('network')}
          >
            <b className="question">
              Can I use this network?
              <span className={`arrow ${isOpen['network'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['network'] ? 'open' : ''}`}>
              Anybody with a compatible cellular plan can roam onto this network
              without them realizing it. This is not a typical hotspot in that
              it cannot be accessed via your device's Wi-Fi settings. These
              hotspots provide cellular coverage exclusively.
            </p>
          </div>
          <div
            className="question-wrapper"
            onClick={() => toggleQuestion('benefits')}
          >
            <b className="question">
              What's in it for us?
              <span className={`arrow ${isOpen['benefits'] ? 'open' : ''}`} />
            </b>
            <p className={`answer ${isOpen['benefits'] ? 'open' : ''}`}>
              We are building a robust network of hotspots that is valuable to
              carriers at scale. As such, every quality site that helps us reach
              this goal is highly valuable to us.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
