import './InternetReimagined.css';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const routerImageUrl = `${REACT_APP_FIREBASE_STORAGE_URL}graphics%2Frouter.png?alt=media&token=9ad50e41-43d9-4695-be4e-7808eca1dc99`;

const InternetReimagined = () => (
  <div className="intro-container">
    <div className="intro-text-block">
      <h2 className="intro-section-header">Internet Re-imagined</h2>
      <p className="intro-block-text">
        High-speed plans are expensive, especially since most users are only
        using a small portion of their bandwidth (under 10%). We take the rest,
        and allow major carriers to roam their cellular customers onto your
        network.
      </p>
      <p className="intro-block-text">
        We'll guarantee $50 a month, minimum, even if the hotspot gets no use.
        Everything is fully automated, we set up a device and you are paid
        monthly, automagically and hassle-free.
      </p>
      <p className="intro-block-text">
        If you have a property, restaurant, bar, club, venue, business, or
        storefront with good foot traffic, we want to pay for your unused
        connection!
      </p>
    </div>
    <div className="intro-img-container">
      <img src={routerImageUrl} alt="Router" className="intro-img" />
    </div>
  </div>
);

export default InternetReimagined;
