import { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal';
import './Intro.css';

const Intro = ({
  signupModalOpen,
  toggleSignupModal,
  loginModalOpen,
  toggleLoginModal,
  updateClient,
}) => {
  const numCircles = 100;
  const circleIndices = Array.from({ length: numCircles }, (_, i) => i + 1);

  const [isVisible, setIsVisible] = useState(true);
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      const currentScrollY = window.scrollY;

      if (currentScrollY > threshold && isVisible) {
        setIsVisible(false);
      } else if (currentScrollY <= threshold && !isVisible) {
        setIsVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  const handleFirstScroll = () => {
    const currentSectionHeight =
      firstSectionRef.current.getBoundingClientRect().height;
    const offset = currentSectionHeight;
    window.scrollBy({ top: offset, behavior: 'smooth' });
  };

  return (
    <>
      <section ref={firstSectionRef} className="background-container">
        <div className="green-background">
          <div className="home-page-content">
            <h1 className="home-page-title">
              We are not your typical <br />
              internet company.
            </h1>
            <h2 className="home-page-subtitle">
              We pay you to plug in a hotspot and earn you money <br />
              for your unused internet!
            </h2>
            <button className="sign-up-button" onClick={toggleSignupModal}>
              Signup
            </button>
            <button className="log-in-button" onClick={toggleLoginModal}>
              Login
            </button>
          </div>
          {signupModalOpen && (
            <Modal
              onClose={toggleSignupModal}
              type="signup"
              setClient={updateClient}
            />
          )}
          {loginModalOpen && (
            <Modal
              onClose={toggleLoginModal}
              type="login"
              setClient={updateClient}
            />
          )}
        </div>
        <div className="circles-container">
          {circleIndices.map((index) => (
            <div
              key={index}
              className={`circle circle-${index}`}
              style={{
                width: `${-50 + 100 * index}px`,
                height: `${-50 + 100 * index}px`,
              }}
            ></div>
          ))}
        </div>
        <div
          className={`scroll-down-arrow fade-in-out-container ${isVisible ? 'visible' : 'hidden'}`}
          onClick={handleFirstScroll}
        >
          V
        </div>
      </section>
      <section ref={secondSectionRef} />
    </>
  );
};

export default Intro;
