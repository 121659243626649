import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';
import { adminPost } from '../../utils';
import {
  CLIENT_TYPE,
  POTENTIAL_CLIENT_STATUS,
  ROLE,
  URLS,
} from '../../constants';
import CustomModal from '../../components/CustomModal';

const PotentialBusinessClients = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filters, setFilters] = useState({
    myClients: true,
    unclaimed: true,
    othersClients: true,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    myClients: true,
    unclaimed: true,
    othersClients: true,
  });
  const [sendingToOps, setSendingToOps] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES, {
          page,
          filters: appliedFilters,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential businesses');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential businesses:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [appliedFilters, page]);

  const handleCheckboxChange = (userId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(userId)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== userId);
      } else {
        return [...prevSelectedIds, userId];
      }
    });
  };

  const handleSendToOps = async () => {
    setSendingToOps(true);
    try {
      const response = await adminPost(URLS.SEND_TO_OPS, {
        userIds: selectedIds,
        type: CLIENT_TYPE.BUSINESS,
      });
      if (!response.ok) {
        console.error('Error sending to Ops');
        setSendingToOps(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error sending to Ops:', error);
      setSendingToOps(false);
    }
    setSendingToOps(false);
  };

  const handleReject = async () => {
    setRejecting(true);
    try {
      const response = await adminPost(URLS.REJECT_POTENTIAL, {
        userIds: selectedIds,
        type: CLIENT_TYPE.BUSINESS,
        by: ROLE.SALES,
      });
      if (!response.ok) {
        console.error('Error rejecting');
        setRejecting(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error rejecting:', error);
      setRejecting(false);
    }
    setRejecting(false);
  };

  const handleCreateAccount = async (user, event) => {
    event.stopPropagation();
    setUsersLoading(true);
    try {
      const response = await adminPost(URLS.CREATE_CLIENT_ACCOUNT, {
        type: CLIENT_TYPE.BUSINESS,
        user,
      });
      if (!response.ok) {
        console.error('Error inviting');
        setUsersLoading(false);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error inviting:', error);
    }
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
    setPage(1);
  };

  const fetchComments = async (potentialBusinessId) => {
    try {
      setCommentsLoading(true);
      const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES_COMMENTS, {
        potentialBusinessId,
      });
      if (!response.ok) {
        console.error('Failed to fetch potential business comments.');
        return;
      }
      const data = await response.json();
      setComments(data.comments);
    } catch (error) {
      console.error('Error fetching potential businesses comments:', error);
    }
    setCommentsLoading(false);
  };

  const handleRowClick = async (user) => {
    setSelectedUser(user);
    await fetchComments(user.id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleSaveChanges = async (updatedUser) => {
    try {
      const response = await adminPost(URLS.UPDATE_POTENTIAL_BUSINESS, {
        updatedUser,
      });
      if (!response.ok) {
        console.error('Error updating user');
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error('Error updating user:', error);
    }
    handleCloseModal();
  };

  const handleWriteComment = async () => {
    try {
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS_COMMENT, {
        comment,
        potentialClientId: selectedUser.id,
      });
      if (!response.ok) {
        console.error('Error adding comment to potential business.');
        return;
      }
      const newComment = await response.json();
      setComments((prevComments) => [newComment, ...prevComments]);
      setComment('');
    } catch (error) {
      console.error('Error adding comment to potential business: ', error);
    }
  };

  return (
    <div className="admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Potential Business Clients</h2>
      <Link
        className="admin-invite-link"
        to="/admin/potential-business-clients/add"
      >
        Add Potential Business
      </Link>
      <br />
      <br />
      *Ideal path for potential clients: {<b>INITIALIZED</b>} ➡️
      {` `}
      {<b>OPS_REVIEW</b>} ➡️ {<b>SALES_REVIEW</b>} ➡️ {<b>ACCOUNT_CREATED</b>}
      <hr />
      <div className="filters-container">
        <button className="admin-invite-link" onClick={applyFilters}>
          Apply Filters
        </button>
        <div className="filters-container-checkboxes">
          <label className="filters-container-checkbox">
            <input
              type="checkbox"
              name="myClients"
              checked={filters.myClients}
              onChange={handleFilterChange}
            />
            My Potential Clients
          </label>
          <label className="filters-container-checkbox">
            <input
              type="checkbox"
              name="unclaimed"
              checked={filters.unclaimed}
              onChange={handleFilterChange}
            />
            Unclaimed Potential Clients
          </label>
          <label className="filters-container-checkbox">
            <input
              type="checkbox"
              name="othersClients"
              checked={filters.othersClients}
              onChange={handleFilterChange}
            />
            Others' Potential Clients
          </label>
        </div>
      </div>
      <hr />
      {sendingToOps || rejecting ? (
        <div>Loading...</div>
      ) : (
        <>
          <button
            className="admin-invite-link"
            onClick={handleSendToOps}
            disabled={selectedIds.length === 0}
          >
            Send to Ops
          </button>
          <button
            className="admin-invite-link"
            onClick={handleReject}
            disabled={selectedIds.length === 0}
          >
            Reject
          </button>
        </>
      )}
      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p style={{ textAlign: 'center' }}>No potential businesses.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th></th>
                <th>Action</th>
                <th>Status*</th>
                <th>Business Name</th>
                <th>Business Title</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Contact Title</th>
                <th>Daily Patrons</th>
                <th>Square Footage</th>
                <th>Created</th>
                <th>Referred By</th>
                <th>Sales Rep</th>
                <th>Ops Rep</th>
                <th>Installer</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr
                  key={user.id}
                  className="custom-row"
                  onClick={() => handleRowClick(user)}
                >
                  <td>
                    <input
                      onClick={(event) => event.stopPropagation()}
                      type="checkbox"
                      disabled={[
                        POTENTIAL_CLIENT_STATUS.OPS_REVIEW,
                        POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED,
                        POTENTIAL_CLIENT_STATUS.SALES_REJECT,
                        POTENTIAL_CLIENT_STATUS.OPS_REJECT,
                      ].includes(user.status)}
                      onChange={() => handleCheckboxChange(user.id)}
                      checked={selectedIds.includes(user.id)}
                    />
                  </td>
                  <td>
                    <button
                      onClick={(event) => handleCreateAccount(user, event)}
                      disabled={
                        user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                      }
                    >
                      Create Account
                    </button>
                  </td>
                  <td>
                    {user.status === POTENTIAL_CLIENT_STATUS.CREATED
                      ? POTENTIAL_CLIENT_STATUS.INITIALIZED
                      : user.status}
                  </td>
                  <td>{user.businessName}</td>
                  <td>{user.title}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.address}</td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.zipCode}</td>
                  <td>{user.contactTitle}</td>
                  <td>{user.dailyPatrons}</td>
                  <td>{user.squareFootage}</td>
                  <td>{getFormattedDate(user.created)}</td>
                  <td>{user.referredBy}</td>
                  <td>{user.createdBy}</td>
                  <td>{user.opsUser}</td>
                  <td>{user.installer}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
      {isModalOpen && (
        <CustomModal onClose={handleCloseModal}>
          <div className="modal-content">
            <h2>Edit User</h2>
            {selectedUser && (
              <form onSubmit={() => handleSaveChanges(selectedUser)}>
                <label>Client Email: {selectedUser.email}</label>
                <br />
                <label>
                  Contact Title:
                  <input
                    type="text"
                    value={selectedUser.contactTitle}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        contactTitle: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Business Name:
                  <input
                    type="text"
                    value={selectedUser.businessName}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        businessName: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Business Title:
                  <input
                    type="text"
                    value={selectedUser.title}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        title: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Phone Number:
                  <input
                    type="text"
                    value={selectedUser.phoneNumber}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Daily Patrons:
                  <input
                    type="text"
                    value={selectedUser.dailyPatrons}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        dailyPatrons: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Square Footage:
                  <input
                    type="text"
                    value={selectedUser.squareFootage}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        squareFootage: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Referred By:
                  <input
                    type="text"
                    value={selectedUser.referredBy}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        referredBy: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Sales Rep:
                  <input type="text" value={selectedUser.createdBy} disabled />
                </label>
                <label>
                  Ops User:
                  <input type="text" value={selectedUser.opsUser} disabled />
                </label>
                <label>
                  Installer:
                  <input disabled type="text" value={selectedUser.installer} />
                </label>
                <label>
                  Came From Website:
                  <input
                    disabled
                    type="text"
                    value={(selectedUser.cameFromWebsite ?? false).toString()}
                  />
                </label>
                <label>
                  Street:
                  <input
                    type="text"
                    value={selectedUser.address}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        address: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  City:
                  <input
                    type="text"
                    value={selectedUser.city}
                    onChange={(e) =>
                      setSelectedUser({ ...selectedUser, city: e.target.value })
                    }
                  />
                </label>
                <label>
                  State:
                  <input
                    type="text"
                    value={selectedUser.state}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        state: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Zip Code:
                  <input
                    type="text"
                    value={selectedUser.zipCode}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        zipCode: e.target.value,
                      })
                    }
                  />
                </label>
                <h3>Comments:</h3>
                <label>
                  <textarea
                    placeholder="Add comments here."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <br />
                  <button
                    disabled={!comment.length}
                    type="button"
                    onClick={handleWriteComment}
                  >
                    Comment
                  </button>
                </label>
                <div>
                  {commentsLoading ? (
                    <Spinner />
                  ) : (
                    comments.map((comment) => (
                      <div className="custom-comment" key={comment.id}>
                        <p>{comment.value}</p>
                        <small>{getFormattedDate(comment.created)}</small>
                        <small>
                          Written by {comment.author}, Role: {comment.type}
                        </small>
                      </div>
                    ))
                  )}
                </div>
                <button type="submit">Save Changes</button>
                <button type="button" onClick={handleCloseModal}>
                  Cancel
                </button>
              </form>
            )}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PotentialBusinessClients;
