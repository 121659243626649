import './HowItWorks.css';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const howItWorksGraphicUrl = `${REACT_APP_FIREBASE_STORAGE_URL}graphics%2Fhowitworks_graphic.png?alt=media&token=756f0600-ae81-442a-bb7a-7601234c4966`;

const HowItWorks = () => {
  return (
    <div className="howitworks-container">
      <section id="howItWorks" className="howitworks-section">
        <h2 className="howitworks-section-header">How it works</h2>
        <div className="howitworks-img-container">
          <img
            src={howItWorksGraphicUrl}
            alt="How It Works"
            className="howitworks-img"
          />
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
