import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientPost } from '../../../utils';
import { URLS } from '../../../constants';
import Spinner from '../../../components/Spinner';
import './Onboarding.css';

const ContractPage = ({ setClient }) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUnsignedContractUrl = async () => {
      try {
        const response = await clientPost(URLS.FETCH_HOST_AGREEMENT);
        const data = await response.json();
        setUrl(data.url);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching unsigned contract URL:', error);
        setLoading(false);
      }
    };

    fetchUnsignedContractUrl();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await clientPost(URLS.CLIENT_SIGN_HOSTING);
      if (!response.ok) {
        console.error('Failed submitting client contract host agreement.');
        setSubmitting(false);
      } else {
        setClient({ status: 'APPOINTMENT', hostingAgreementSigned: true });
        navigate('/account');
      }
    } catch (error) {
      console.error('Failed submitting client contract host agreement.');
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setAgreementChecked(event.target.checked);
  };

  return (
    <div className="contract-page-container">
      {!loading && url && (
        <p>Please read and sign your hosting contract agreement:</p>
      )}

      {loading ? (
        <Spinner />
      ) : (
        url && (
          <iframe
            className="contract-page-iframe"
            title="unsigned-hosting-agreement"
            src={url}
            width="100%"
            height="600px"
          ></iframe>
        )
      )}
      {!loading && url && (
        <form className="contract-page-form" onSubmit={handleSubmit}>
          <label className="contract-page-label">
            <input
              type="checkbox"
              name="agreement"
              onChange={handleCheckboxChange}
            />
            I agree to the terms and conditions
          </label>
          {!submitting ? (
            <button
              className="contract-page-button"
              type="submit"
              disabled={!agreementChecked}
            >
              Continue
            </button>
          ) : (
            <Spinner />
          )}
        </form>
      )}
    </div>
  );
};

export default ContractPage;
