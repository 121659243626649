import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';

const ClientDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setDocumentsLoading(true);
        const response = await adminPost(URLS.GET_CLIENT_DOCUMENTS, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch client documents');
          return;
        }
        const data = await response.json();
        setDocuments(data.documents);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching client documents:', error);
      }
      setDocumentsLoading(false);
    };
    fetchDocuments();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <div className="admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Client Documents</h2>
      <hr />
      {documentsLoading ? (
        <Spinner />
      ) : !documents.length ? (
        <p style={{ textAlign: 'center' }}>No client documents.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Client Email</th>
                <th>Business Name</th>
                <th>Address</th>
                <th>Document Status</th>
                <th>Document Link</th>
                <th>Sales Rep</th>
                <th>Ops Rep</th>
                <th>Installer</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document) => (
                <tr key={document.id}>
                  <td>{document.clientEmail}</td>
                  <td>{document.businessName}</td>
                  <td>{document.address}</td>
                  <td>{document.status}</td>
                  <td>
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                  <td>{document.salesRep}</td>
                  <td>{document.opsUser}</td>
                  <td>{document.installer}</td>
                  <td>{getFormattedDate(document.created)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDocuments;
