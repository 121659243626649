import { useState } from 'react';
import './AdminInvite.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Spinner from '../../components/Spinner';
import { URLS } from '../../constants';
import { post } from '../../utils';

const AdminInvite = () => {
  const [toEmail, setToEmail] = useState('');
  const [role, setRole] = useState('ADMIN');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const token = Cookies.get('token');
  const username = Cookies.get('username');
  const email = Cookies.get('email');

  const handleEmailChange = (e) => {
    setToEmail(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleInvite = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const result = await post(URLS.INVITE_INFRA_USER, {
        token,
        email,
        username,
        toEmail,
        role,
      });
      if (result.status === 200) {
        setSubmitted(true);
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Error in handleInvite: ', error.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="admin-invite-container">
      <Link className="custom-form-1-back-link" to="/admin/users">
        Back to Users
      </Link>
      <div>
        <form onSubmit={handleInvite} className="admin-invite-form">
          <h2>Invite User</h2>
          {submitted ? (
            <p>
              Invite sent to <b>{toEmail}</b>
              <br />
              for role: <b>{role}</b>
              <br />
            </p>
          ) : submitting ? (
            <Spinner />
          ) : (
            <>
              <label>Email:</label>
              <input
                type="email"
                value={toEmail}
                onChange={handleEmailChange}
                required
                placeholder="Enter Email"
                maxLength={40}
              />
              <label>Role:</label>
              <select value={role} onChange={handleRoleChange}>
                <option value="ADMIN">ADMIN</option>
                <option value="SALES">SALES</option>
                <option value="OPS">OPS</option>
                <option value="INSTALLER">INSTALLER</option>
              </select>
              <button disabled={!email} type="submit">
                Invite
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminInvite;
