import './MissionStatement.css';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const radioSiteUrl = `${REACT_APP_FIREBASE_STORAGE_URL}graphics%2FradioSite.jpg?alt=media&token=3ba30fb4-1914-45c9-bd64-8b8b923a2dc2`;

const MissionStatement = () => {
  return (
    <div className="mission-container">
      <div className="mission-text-block">
        <h2 className="mission-section-header">Mission Statement</h2>
        <p className="mission-block-text">
          Our mission is to bridge the gap between telecom giants and common
          internet consumers. Eliminating the barriers of entry for service
          providers, allowing anybody to benefit from hosting telecom services
          for MVOs like AT&T and T-Mobile.
        </p>
        <p className="mission-block-text">
          Until now, these opportunities were limited to multi-billion dollar
          corporations like American Tower and Crown Castle. We believe that we
          can take this multi-billion (nearly trillion) dollar industry and
          remove all barriers of entry so that businesses like yours can get a
          slice of the pie.
        </p>
      </div>
      <div className="mission-img-container">
        <img src={radioSiteUrl} alt="Radio Site" className="mission-img" />
      </div>
    </div>
  );
};

export default MissionStatement;
