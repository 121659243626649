import { useNavigate } from 'react-router-dom';
import './Careers.css';

const jobs = [
  { title: 'Business Development Associate', location: 'New York' },
  { title: 'Business Development Associate', location: 'Miami' },
  { title: 'Business Development Associate', location: 'Las Vegas' },
  { title: 'Business Development Associate', location: 'Oaxaca' },
  { title: 'Business Development Associate', location: 'Your City' },
];

const Careers = () => {
  const navigate = useNavigate();
  return (
    <div className="careers-container">
      <section id="careers">
        <h2 className="careers-section-header">Careers</h2>
        <div className="text-block">
          <div className="block-text">
            {jobs.map((job, index) => {
              return (
                <div
                  className="link-container"
                  onClick={() =>
                    navigate('/jobs/business-development-regional-lead')
                  }
                  key={index}
                >
                  <div
                    className="career-link"
                    to="/jobs/business-development-regional-lead"
                  >
                    {job.title}
                    <br />
                    <span className="grey-text">({job.location})</span>
                    <br />
                    <br />
                  </div>
                </div>
              );
            })}
            <p className="stay-tuned">Stay tuned for more job postings!</p>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Careers;
