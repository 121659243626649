import { useState } from 'react';
import { clientPost } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants';
import { useStripeConnect } from './useStripeConnect';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import './Onboarding.css';

const PayoutPage = ({ client, setClient }) => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  const navigate = useNavigate();

  const handleSkipSetup = async () => {
    try {
      const response = await clientPost(URLS.CLIENT_SKIP_PAYOUT);
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        console.error('Failed to skip payout.');
      }
    } catch (error) {
      console.error('Error skipping payout:', error);
    }
  };

  const createAccount = async () => {
    try {
      setAccountCreatePending(true);
      setError(false);
      const response = await clientPost(URLS.STRIPE_ACCOUNT);
      const json = await response.json();
      setAccountCreatePending(false);
      const { account, error } = json;
      if (account) {
        setConnectedAccountId(account);
      }
      if (error) {
        setError(true);
      }
    } catch (error) {
      setAccountCreatePending(false);
      setError(true);
    }
  };

  const exitOnboarding = async () => {
    try {
      setOnboardingExited(true);
      const response = await clientPost(URLS.CLIENT_FINISH_PAYOUT, {
        connectedAccountId,
      });
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        console.error('Failed to finish payout.');
      }
    } catch (error) {
      console.error('Error exitting payout:', error);
    }
  };

  return (
    <div className="payout-page-container">
      <div className="banner">
        <h2>Select a Payout Method:</h2>
      </div>
      <div className="payout-page-content">
        {connectedAccountId && !stripeConnectInstance && (
          <h2>Add information to start accepting money</h2>
        )}
        {!connectedAccountId && (
          <p>Rhythm Wireless: Join our network of hosts and earn money!</p>
        )}
        {!accountCreatePending && !connectedAccountId && (
          <div>
            <button className="payout-page-button" onClick={createAccount}>
              Connect with Stripe
            </button>
          </div>
        )}
        {stripeConnectInstance && (
          <ConnectComponentsProvider
            className="stripe-provider"
            connectInstance={stripeConnectInstance}
          >
            <ConnectAccountOnboarding
              className="stripe-inner-provider"
              onExit={() => exitOnboarding()}
            />
          </ConnectComponentsProvider>
        )}
        {error && <p className="error">Something went wrong!</p>}
        {(connectedAccountId || accountCreatePending || onboardingExited) && (
          <div className="dev-callout">
            {accountCreatePending && <p>Creating a connected account...</p>}
          </div>
        )}
        <button
          className="payout-page-button"
          type="button"
          onClick={handleSkipSetup}
        >
          Set up later
        </button>
      </div>
    </div>
  );
};

export default PayoutPage;
