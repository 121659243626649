import { useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../constants';
import { post } from '../../utils';
import './Forms.css';

const Businesses = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: '',
    dailyPatrons: '',
    squareFootage: '',
    contactTitle: '',
    title: '',
    referredBy: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.SUBMIT_BUSINESS_FORM, formData);
      if (response.ok) {
        setSubmitted(true);
        setErrorMessage('');
      } else {
        const errorMessage = await response.text();
        setErrorMessage(errorMessage || 'Error submitting form.');
      }
    } catch (error) {
      setErrorMessage(
        'Our servers are currently down, please try again later.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="business-container">
      <section id="businesses">
        <h2 className="business-section-header">Businesses</h2>
        <h2 className="business-section-subtitle">
          Shops, Bars, Restaurants, Venues, Gyms, Community Centers, etc.
        </h2>
        <div className="text-block">
          <form onSubmit={handleSubmit} className="businesses-form">
            <div className="form-group">
              <input
                placeholder="Full Name"
                type="text"
                id="contactTitle"
                name="contactTitle"
                value={formData.contactTitle}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Phone Number"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="form-input"
                maxLength="22"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Name of Business"
                type="text"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Business Title (CEO, Owner, etc.)"
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Street Address"
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-input"
                maxLength="70"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="City"
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="State"
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="form-input"
                maxLength="40"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Zip Code"
                type="text"
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                className="form-input"
                maxLength="20"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Daily Number of Patrons"
                type="text"
                id="dailyPatrons"
                name="dailyPatrons"
                value={formData.dailyPatrons}
                onChange={handleChange}
                className="form-input"
                pattern="[0-9]*"
                maxLength="10"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Square Footage"
                type="text"
                id="squareFootage"
                name="squareFootage"
                value={formData.squareFootage}
                onChange={handleChange}
                className="form-input"
                pattern="[0-9]*"
                maxLength="10"
              />
            </div>
            <div className="form-group">
              <input
                placeholder="Referred By (Optional)"
                type="text"
                id="referredBy"
                name="referredBy"
                value={formData.referredBy}
                onChange={handleChange}
                className="form-input"
                maxLength="50"
              />
            </div>
            <Link
              to="/terms"
              className="form-agreement-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
            {errorMessage && <div className="error-box">{errorMessage}</div>}
            {submitted && (
              <div className="success-box">
                Thanks for signing up for our waitlist! We will get back to you
                soon to confirm if your location is a match.
              </div>
            )}
            <button
              type="submit"
              className="form-submit"
              disabled={
                submitting ||
                submitted ||
                !formData.businessName ||
                !formData.address ||
                !formData.city ||
                !formData.state ||
                !formData.zipCode ||
                !formData.phoneNumber ||
                !formData.email ||
                !formData.dailyPatrons ||
                !formData.squareFootage ||
                !formData.contactTitle ||
                !formData.title
              }
            >
              {submitting ? (
                <div className="spinner" />
              ) : submitted ? (
                'Submitted!'
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
        <br />
        <br />
        <br />
      </section>
    </div>
  );
};

export default Businesses;
