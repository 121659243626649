import { useEffect, useState } from 'react';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import getFormattedDate from './utils';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';

const ManageOpsSites = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_OPS_SITES, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch ops sites');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching ops sites:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <div className="custom-form-1-admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Manage My Sites</h2>
      <hr />
      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p style={{ textAlign: 'center' }}>
          You are not currently managing any sites.
        </p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Business Name</th>
                <th>Client Email</th>
                <th>Phone Number</th>
                <th>Address</th>
                <th>Created</th>
                <th>Sales Rep</th>
                <th>Installer</th>
                <th>Ops User</th>
                <th>Client Status</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.fullName}</td>
                  <td>{user.businessName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>
                    {user.streetAddress}, {user.city}, {user.state}{' '}
                    {user.zipCode}
                  </td>
                  <td>{getFormattedDate(user.created)}</td>
                  <td>{user.createdBy}</td>
                  <td>{user.installer}</td>
                  <td>{user.opsUser}</td>
                  <td>{user.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageOpsSites;
