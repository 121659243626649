import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <p>
        To get in touch, please email us at{' '}
        <a href={`mailto:info@rhythmwireless.io`} className="contact-us-email">
          info@rhythmwireless.io
        </a>
      </p>
    </div>
  );
};

export default ContactUs;
