import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { POTENTIAL_SITE_STATUS, URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';

const AssignInstallerActiveSites = () => {
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [updatedSites, setUpdatedSites] = useState([]);
  const [allInstallers, setAllInstallers] = useState([]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setSitesLoading(true);
        const response = await adminPost(URLS.GET_OPS_REVIEW_SITES, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch ops review sites');
          return;
        }
        const data = await response.json();
        const updatedSitesArray = data.sites.map((site) => ({
          ...site,
          installer: '',
        }));
        setUpdatedSites(updatedSitesArray);
        setSites(data.sites);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching ops review sites:', error);
      }
      setSitesLoading(false);
    };
    fetchSites();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleApprove = async (siteId, installer, event) => {
    event.stopPropagation();
    const updated = sites.map((site) =>
      site.id === siteId
        ? {
            ...site,
            status: POTENTIAL_SITE_STATUS.APPROVED,
            installer,
          }
        : site,
    );
    setSites(updated);
    try {
      const response = await adminPost(URLS.APPROVE_SITE, {
        siteId,
        installer,
      });
      if (!response.ok) {
        console.error('Failed to approve site');
      }
      const updatedSitesArray = updatedSites.map((site) =>
        site.id === siteId ? { ...site, installer: '' } : site,
      );
      setUpdatedSites(updatedSitesArray);
    } catch (error) {
      console.error('Error approving site:', error);
    }
  };

  const handleDeny = async (siteId, event) => {
    event.stopPropagation();
    const updated = sites.map((site) =>
      site.id === siteId
        ? {
            ...site,
            status: POTENTIAL_SITE_STATUS.REJECTED,
          }
        : site,
    );
    setSites(updated);
    try {
      const response = await adminPost(URLS.REJECT_SITE, {
        siteId,
      });
      if (!response.ok) {
        console.error('Failed to deny site');
      }
      const updatedSitesArray = updatedSites.map((site) =>
        site.id === siteId ? { ...site, installer: '' } : site,
      );
      setUpdatedSites(updatedSitesArray);
    } catch (error) {
      console.error('Error denying site:', error);
    }
  };

  const handleInstallerChange = (siteId, installerEmail) => {
    setUpdatedSites((prevSites) =>
      prevSites.map((site) =>
        site.id === siteId ? { ...site, installer: installerEmail } : site,
      ),
    );
  };

  return (
    <div className="custom-form-1-admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Assign Installer for Active Sites (Existing Accounts)</h2>
      <hr />
      {sitesLoading ? (
        <Spinner />
      ) : !sites.length ? (
        <p style={{ textAlign: 'center' }}>No active sites.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Status</th>
                <th>Email</th>
                <th>Street Address</th>
                <th>Daily Patrons</th>
                <th>Square Footage</th>
                <th>Created</th>
                <th>Installer</th>
              </tr>
            </thead>
            <tbody>
              {sites.map((site, index) => (
                <tr key={site.id} className="custom-row">
                  <td>
                    <button
                      onClick={(event) =>
                        handleApprove(
                          site.id,
                          updatedSites[index].installer,
                          event,
                        )
                      }
                      disabled={
                        !updatedSites[index].installer ||
                        site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                      }
                    >
                      Approve
                    </button>
                    <button
                      onClick={(event) => handleDeny(site.id, event)}
                      disabled={
                        site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                      }
                    >
                      Deny
                    </button>
                    <select
                      onClick={(event) => event.stopPropagation()}
                      onChange={(e) =>
                        handleInstallerChange(site.id, e.target.value)
                      }
                      value={updatedSites[index].installer}
                      disabled={
                        site.status !== POTENTIAL_SITE_STATUS.OPS_REVIEW
                      }
                    >
                      <option
                        onClick={(event) => event.stopPropagation()}
                        value=""
                      >
                        Select Installer
                      </option>
                      {allInstallers.map((installer) => (
                        <option
                          onClick={(event) => event.stopPropagation()}
                          key={installer.email}
                          value={installer.email}
                        >
                          {installer.email}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>{site.status}</td>
                  <td>{site.email}</td>
                  <td>{site.address}</td>
                  <td>{site.dailyPatrons}</td>
                  <td>{site.squareFootage}</td>
                  <td>{getFormattedDate(site.created)}</td>
                  <td>{site.installer}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignInstallerActiveSites;
