import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notfound-container">
      <h2 className="notfound-section-header">Page Not Found</h2>
      <p>Whoops, this page does not exist.</p>
      <br />
      <br />
      <Link to="/" className="home-link">
        Go back to home
      </Link>
    </div>
  );
};

export default NotFound;
