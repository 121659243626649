import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { adminPost } from '../../utils';
import { CLIENT_TYPE, POTENTIAL_CLIENT_STATUS, URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';
import './Admin.css';
import CustomModal from '../../components/CustomModal';

const ManageOpsPotentialSites = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [allInstallers, setAllInstallers] = useState([]);
  const [updatedUsers, setUpdatedUsers] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_READY_FOR_OPS_REVIEW, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential clients');
          return;
        }
        const data = await response.json();
        const updatedUsersArray = data.users.map((user) => ({
          ...user,
          opsNotes: '',
          installer: '',
        }));
        setUpdatedUsers(updatedUsersArray);
        const usersWithOpsNotes = data.users.map((user) => ({
          ...user,
          opsNotes: user.opsNotes || [],
        }));
        setUsers(usersWithOpsNotes);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential clients:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleApprove = async (
    potentialClientId,
    type,
    opsNotes,
    installer,
    event,
  ) => {
    event.stopPropagation();
    const updated = users.map((user) =>
      user.id === potentialClientId
        ? {
            ...user,
            status: POTENTIAL_CLIENT_STATUS.SALES_REVIEW,
            installer,
          }
        : user,
    );
    setUsers(updated);
    try {
      const response = await adminPost(URLS.SEND_TO_SALES_REVIEW, {
        potentialClientId,
        type,
        opsNotes,
        installer,
      });
      if (!response.ok) {
        console.error('Failed to approve user');
      }
      const updatedUsersArray = updatedUsers.map((user) =>
        user.id === potentialClientId
          ? { ...user, opsNotes: '', installer: '' }
          : user,
      );
      setUpdatedUsers(updatedUsersArray);
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleDeny = async (userId, type, opsNotes, event) => {
    event.stopPropagation();
    const updated = users.map((user) =>
      user.id === userId
        ? {
            ...user,
            status: POTENTIAL_CLIENT_STATUS.OPS_REJECT,
          }
        : user,
    );
    setUsers(updated);
    try {
      const response = await adminPost(URLS.SEND_TO_OPS_REJECT, {
        userId,
        type,
        opsNotes,
      });
      if (!response.ok) {
        console.error('Failed to deny user');
      }
      const updatedUsersArray = updatedUsers.map((user) =>
        user.id === userId ? { ...user, opsNotes: '', installer: '' } : user,
      );
      setUpdatedUsers(updatedUsersArray);
    } catch (error) {
      console.error('Error denying user:', error);
    }
  };

  const handleInstallerChange = (userId, installerEmail) => {
    setUpdatedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, installer: installerEmail } : user,
      ),
    );
  };

  const handleOpsNotesChange = (userId, opsNotes) => {
    setUpdatedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, opsNotes } : user,
      ),
    );
  };

  const fetchComments = async (potentialBusinessId) => {
    try {
      setCommentsLoading(true);
      const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES_COMMENTS, {
        potentialBusinessId,
      });
      if (!response.ok) {
        console.error('Failed to fetch potential business comments.');
        return;
      }
      const data = await response.json();
      setComments(data.comments);
    } catch (error) {
      console.error('Error fetching potential businesses comments:', error);
    }
    setCommentsLoading(false);
  };

  const handleRowClick = async (user) => {
    setSelectedUser(user);
    await fetchComments(user.id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleWriteComment = async () => {
    try {
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS_COMMENT, {
        comment,
        potentialClientId: selectedUser.id,
      });
      if (!response.ok) {
        console.error('Error adding comment to potential business.');
        return;
      }
      const newComment = await response.json();
      setComments((prevComments) => [newComment, ...prevComments]);
      setComment('');
    } catch (error) {
      console.error('Error adding comment to potential business: ', error);
    }
  };

  return (
    <div className="custom-form-1-admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Manage Potential Sites</h2>
      <hr />
      *Ideal path for potential clients: {<b>INITIALIZED</b>} ➡️
      {` `}
      {<b>OPS_REVIEW</b>} ➡️ {<b>SALES_REVIEW</b>} ➡️ {<b>ACCOUNT_CREATED</b>}
      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p style={{ textAlign: 'center' }}>No potential businesses.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Status</th>
                <th>Client Name</th>
                <th>Business Name</th>
                <th>Business Title</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Street Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Daily Patrons</th>
                <th>Square Footage</th>
                <th>Created</th>
                <th>Referred By</th>
                <th>Sales Rep</th>
                <th>Installer</th>
                <th>Ops User</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user.id}
                  className="custom-row"
                  onClick={() => handleRowClick(user)}
                >
                  <td>
                    <button
                      onClick={(event) =>
                        handleApprove(
                          user.id,
                          user.type,
                          updatedUsers[index].opsNotes,
                          updatedUsers[index].installer,
                          event,
                        )
                      }
                      disabled={
                        !updatedUsers[index].installer ||
                        (user.status !== POTENTIAL_CLIENT_STATUS.OPS_REVIEW &&
                          user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW)
                      }
                    >
                      Approve
                    </button>
                    <button
                      onClick={(event) =>
                        handleDeny(
                          user.id,
                          user.type,
                          updatedUsers[index].opsNotes,
                          event,
                        )
                      }
                      disabled={
                        user.status ===
                          POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED ||
                        user.status === POTENTIAL_CLIENT_STATUS.SALES_REJECT ||
                        user.status === POTENTIAL_CLIENT_STATUS.OPS_REJECT
                      }
                    >
                      Deny
                    </button>
                    <select
                      onClick={(event) => event.stopPropagation()}
                      onChange={(e) =>
                        handleInstallerChange(user.id, e.target.value)
                      }
                      value={updatedUsers[index].installer}
                      disabled={
                        user.status !== POTENTIAL_CLIENT_STATUS.OPS_REVIEW &&
                        user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                      }
                    >
                      <option
                        onClick={(event) => event.stopPropagation()}
                        value=""
                      >
                        Select Installer
                      </option>
                      {allInstallers.map((installer) => (
                        <option
                          onClick={(event) => event.stopPropagation()}
                          key={installer.email}
                          value={installer.email}
                        >
                          {installer.email}
                        </option>
                      ))}
                    </select>
                    <textarea
                      onClick={(event) => event.stopPropagation()}
                      type="text"
                      placeholder="Add Ops Notes"
                      value={updatedUsers[index].opsNotes}
                      onChange={(e) =>
                        handleOpsNotesChange(user.id, e.target.value)
                      }
                      disabled={
                        user.status ===
                          POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED ||
                        user.status === POTENTIAL_CLIENT_STATUS.SALES_REJECT ||
                        user.status === POTENTIAL_CLIENT_STATUS.OPS_REJECT
                      }
                    />
                  </td>
                  <td>{user.status}</td>
                  <td>
                    {user.type === CLIENT_TYPE.BUSINESS
                      ? user.contactTitle
                      : user.fullName}
                  </td>
                  <td>{user.businessName}</td>
                  <td>{user.title}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>
                    {user.type === CLIENT_TYPE.BUSINESS
                      ? user.address
                      : user.streetAddress}
                  </td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.zipCode}</td>
                  <td>{user.dailyPatrons}</td>
                  <td>{user.squareFootage}</td>
                  <td>{getFormattedDate(user.created)}</td>
                  <td>{user.referredBy}</td>
                  <td>{user.createdBy}</td>
                  <td>{user.installer}</td>
                  <td>{user.opsUser}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
      {isModalOpen && (
        <CustomModal onClose={handleCloseModal}>
          <div className="modal-content">
            {selectedUser && (
              <div>
                <h3>Potential Client Info:</h3>
                <p>Status: {selectedUser.status}</p>
                <p>Business Name: {selectedUser.businessName}</p>
                <p>Full Name: {selectedUser.contactTitle}</p>
                <p>Email: {selectedUser.email}</p>
                <p>Phone: {selectedUser.phoneNumber}</p>
                <p>
                  Address: {selectedUser.address}, {selectedUser.city},{' '}
                  {selectedUser.state} {selectedUser.zipCode}
                </p>
                <p>Sales Rep: {selectedUser.createdBy}</p>
                <p>Ops User: {selectedUser.opsUser ?? 'N/A'}</p>
                <p>Installer: {selectedUser.installer ?? 'N/A'}</p>
                <h3>Comments:</h3>
                <label>
                  <textarea
                    placeholder="Add comments here."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <br />
                  <button
                    disabled={!comment.length}
                    type="button"
                    onClick={handleWriteComment}
                  >
                    Comment
                  </button>
                </label>
                <div>
                  {commentsLoading ? (
                    <Spinner />
                  ) : (
                    comments.map((comment) => (
                      <div className="custom-comment" key={comment.id}>
                        <p>{comment.value}</p>
                        <small>{getFormattedDate(comment.created)}</small>
                        <small>
                          Written by {comment.author}, Role: {comment.type}
                        </small>
                      </div>
                    ))
                  )}
                </div>
                <button type="button" onClick={handleCloseModal}>
                  Exit
                </button>
              </div>
            )}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ManageOpsPotentialSites;
