import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';
import { adminPost } from '../../utils';
import { POTENTIAL_CLIENT_STATUS, URLS } from '../../constants';

const PotentialHouseholdClients = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_POTENTIAL_HOUSEHOLDS, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential households');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential households:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={i === page ? 'active' : ''}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter(
          (selectedEmail) => selectedEmail !== email,
        );
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  return (
    <div className="admin-dash-container">
      <Link to="/admin/dashboard" className="custom-form-1-back-link">
        Back To Dashboard
      </Link>
      <h2>Potential Household Clients</h2>
      <Link
        className="admin-invite-link"
        to="/admin/potential-household-clients/add"
      >
        Add Potential Household
      </Link>
      <br />
      <br />
      <hr />
      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p style={{ textAlign: 'center' }}>No potential households.</p>
      ) : (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th></th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Ownership</th>
                <th>Created</th>
                <th>Referred By</th>
                <th>Sales Rep</th>
                <th>Status</th>
                <th>Installer</th>
                <th>Ops Notes</th>
                <th>Approve</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email}>
                  <td>
                    <input
                      type="checkbox"
                      disabled={[
                        POTENTIAL_CLIENT_STATUS.OPS_REVIEW,
                        POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED,
                        POTENTIAL_CLIENT_STATUS.SALES_REJECT,
                        POTENTIAL_CLIENT_STATUS.OPS_REJECT,
                      ].includes(user.status)}
                      onChange={() => handleCheckboxChange(user.email)}
                      checked={selectedEmails.includes(user.email)}
                    />
                  </td>
                  <td>{user.fullName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.streetAddress}</td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.zipCode}</td>
                  <td>{user.ownership}</td>
                  <td>{getFormattedDate(user.created)}</td>
                  <td>{user.referredBy}</td>
                  <td>{user.createdBy}</td>
                  <td>{user.status ?? POTENTIAL_CLIENT_STATUS.INITIALIZED}</td>
                  <td>{user.installer}</td>
                  <td>
                    {user.opsNotes?.map((note, i) => (
                      <div key={i}>
                        {note}
                        <hr />
                      </div>
                    ))}
                  </td>
                  <td>
                    <button
                      disabled={
                        user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                      }
                    >
                      Invite
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={prevPage} disabled={page === 1}>
              Prev
            </button>
            {renderPageNumbers()}
            <button onClick={nextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PotentialHouseholdClients;
