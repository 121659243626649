import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientPost } from '../../../utils';
import { URLS } from '../../../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Onboarding.css';

const localizer = momentLocalizer(moment);

const SchedulingPage = ({ client, setClient }) => {
  const [selectedInstaller, setSelectedInstaller] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [appointmentScheduled, setAppointmentScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (client && client.installer) {
      const getInstallerMetadata = async () => {
        try {
          const response = await clientPost(URLS.GET_INSTALLER_METADATA, {
            installer: client.installer,
          });
          if (response.ok) {
            const data = await response.json();
            setSelectedInstaller(data.installer);
          } else {
            console.error('Failed to fetch installer metadata from backend');
          }
        } catch (error) {
          console.error(
            'Error fetching installer metadata from backend:',
            error,
          );
        }
      };

      getInstallerMetadata();
    }
  }, [client]);

  const formatEvents = (events) => {
    const availableEvents = events.filter((event) => event.title !== 'Busy');

    return availableEvents.map((event) => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }));
  };

  const isSelectable = (event) => {
    const { start } = event;
    const currentTime = moment();
    const startTime = moment(start);
    if (startTime.isBefore(currentTime)) {
      return false;
    }
    for (let i = 0; i < 6; i++) {
      const slotStart = startTime.clone().add(i * 30, 'minutes');
      const slotEnd = slotStart.clone().add(30, 'minutes');
      const slotEvent = selectedInstaller.availability.find(
        (e) =>
          moment(e.start).isSame(slotStart) &&
          moment(e.end).isSame(slotEnd) &&
          e.title !== 'Busy',
      );
      if (!slotEvent) return false;
    }
    return true;
  };

  const handleSelectEvent = (event) => {
    if (isSelectable(event)) {
      setSelectedEvent(event);
    } else {
      setSelectedEvent(null);
    }
  };

  const handleSubmit = async () => {
    if (selectedEvent) {
      setAppointmentScheduled(true);
      setScheduledDate(selectedEvent.start);
    }
  };

  const continueToDashboard = async () => {
    try {
      const response = await clientPost(URLS.CLIENT_FINISH_APPOINTMENT, {
        scheduledDate,
        scheduledDateString: scheduledDate.toString(),
        installerEmail: client.installer,
      });
      if (response.ok) {
        setClient({ status: 'PAYOUT' });
        navigate('/account');
      } else {
        console.error('Failed to finish client onboarding.');
      }
    } catch (error) {
      console.error('Failed to finish client onboarding.', error);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = isSelectable(event) ? '#3174ad' : '#d3d3d3';

    if (selectedEvent) {
      const selectedStartTime = moment(selectedEvent.start);
      const selectedEndTime = selectedStartTime.clone().add(3, 'hours');

      if (
        moment(event.start).isBetween(
          selectedStartTime,
          selectedEndTime,
          null,
          '[)',
        )
      ) {
        return {
          style: {
            backgroundColor: 'darkgreen',
          },
        };
      }
    }

    return {
      style: {
        backgroundColor,
      },
    };
  };

  return (
    <div className="scheduling-page-container">
      {appointmentScheduled ? (
        <div className="appointment-scheduled-box">
          <p>
            Your appointment will be scheduled for: {scheduledDate.toString()}
          </p>
          <button
            className="scheduling-page-button"
            onClick={continueToDashboard}
          >
            Continue
          </button>
        </div>
      ) : (
        <>
          <h2>Schedule an installation (3-hour window):</h2>
          {selectedInstaller.availability && (
            <div style={{ height: '500px' }}>
              <Calendar
                localizer={localizer}
                events={formatEvents(selectedInstaller.availability)}
                defaultView="week"
                views={['week', 'day']}
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
                selectable={false}
                onSelectSlot={() => {}}
              />
            </div>
          )}
          <button
            className="scheduling-page-button"
            onClick={handleSubmit}
            disabled={!selectedEvent}
          >
            Submit
          </button>
        </>
      )}
    </div>
  );
};

export default SchedulingPage;
